// Initialize graphToken and authCode with null
let graphToken = null;
let authCode = null;
const environment = process.env.APP_ENV;
let forMSGraphAccess = true;
if (environment === 'development') {
  console.log("Development environment");
  forMSGraphAccess = false;
}


Office.onReady((info) => {
  if (info.host === Office.HostType.Outlook) {
    console.log("Starting Office.onReady");
    // Use the hostName property to determine the running environment
    const hostName = Office.context.mailbox.diagnostics.hostName;
    if (hostName === "OutlookIOS" || hostName === "OutlookAndroid") {
      // It's running on Outlook mobile (iOS or Android), call signIn method
      console.log("Mobile detected");
      signIn();
    } else {
      console.log("Desktop or web detected");
      // It's running on desktop or web, call OfficeRuntime for auth token
      OfficeRuntime.auth.getAccessToken({ allowSignInPrompt: true,  forMSGraphAccess: forMSGraphAccess })
        .then((token) => {
          // Save the token in the graphToken variable
          graphToken = token;
          console.log("graphToken successfully obtained:" + graphToken);
          //Call setInitialContent with authorizeByToken true
          setInitialContent(true);
        })
        .catch((error) => {
          console.error("Error getting access token, retrying with Dialog API: ", error + " " + error.code);
          //If sso failed, try with Dialog API
          signIn();
        });
    }
    //Now either graphToken or authCode is set, so we can continue with preparing the UI
    toggleDisplay("sideload-msg", "none");
    ["move-to-deleted", "report-and-move-to-deleted"].forEach(id => {
      const button = document.getElementById(id);
      button.addEventListener("click", () => handleButtonClick(button));
    });
  }
});

let dialog = null;

function signIn() {
  Office.context.ui.displayDialogAsync(process.env.DIALOG_URL,
    { height: 60, width: 30, displayInIframe: false },
    function (result) {
      dialog = result.value;
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, processAuthMessage);
    }
  );
}

function processAuthMessage(arg) {
  const message = JSON.parse(arg.message);
  if (message.code) {
    console.log("Authorization code successfully obtained: " + message.code);
    authCode = message.code;
    //Call setInitialContent with authorizeByToken false
    setInitialContent(false);
  } else if (message.error) {
    console.error("Authentication error: ", message.error);
    // If this fails, we have no alternative for authentication, thus show error message
    toggleDisplay("app-body-api-not-accessible", "flex");
  }
  // Close the dialog
  dialog.close();
}

function toggleDisplay(elementId, displayStyle) {
  const element = document.getElementById(elementId);
  console.log("Toggling display of " + elementId + " to " + displayStyle);
  if (element) {
    element.style.display = displayStyle;
  }
}

function handleButtonClick(button) {
  button.setAttribute('disabled', 'disabled');
  button.innerHTML = '<div class="loader"></div>';
  button.classList.add('disabled');
  if (button.id === "move-to-deleted") {
    moveCurrentEmailToDeleted();
  } else if (button.id === "report-and-move-to-deleted") {
    reportCurrentEmailAndMoveToDeleted();
  }
}
async function setInitialContent(authorizeByToken: boolean) {
  const restId = getItemRestId();
  if (authorizeByToken) {
    hasDispatchByAccessToken(restId, graphToken)
      .then((res) => {
        //Pass authorizeByToken to handleDispatchResponse to retry with authCode if 500 error
        handleDispatchResponse(res, authorizeByToken);
      })
      .catch(function (error) {
        toggleDisplay("app-body-api-not-accessible", "flex");
        console.error(error);
      });
  } else {
    hasDispatchByAuthCode(restId, authCode)
      .then((res) => {
        handleDispatchResponse(res, authorizeByToken);
      })
      .catch(function (error) {
        toggleDisplay("app-body-api-not-accessible", "flex");
        console.error(error);
      });
  }
}

function handleDispatchResponse(response, authorizeByToken) {
  if (!response.ok) {
    if (response.status === 404) {
      toggleDisplay("app-body-dispatch-not-exists", "flex");
      //forwardCurrentEmailToHelpdesk();
    } else {
      if (authorizeByToken) {
        console.log("Retrying with authCode due to 500 error");
        signIn();
      }
      toggleDisplay("app-body-api-not-accessible", "flex");
    }
  } else {
    toggleDisplay("app-body-dispatch-exists", "flex");
  }
}
//Need to call different API for restID, if on mobile
function getItemRestId() {
  if (Office.context.mailbox.diagnostics.hostName === 'OutlookIOS' || Office.context.mailbox.diagnostics.hostName === 'OutlookAndroid') {
    // itemId is already REST-formatted.
    console.log("Outlook Mobile detected")
    return Office.context.mailbox.item.itemId;
  } else {
    // Convert to an item ID for API v2.0.
    console.log("Outlook Desktop or Web detected")
    return Office.context.mailbox.convertToRestId(
      Office.context.mailbox.item.itemId,
      Office.MailboxEnums.RestVersion.v2_0
    );
  }
}

function forwardCurrentEmailToHelpdesk() {
  const restId = getItemRestId();
  if (graphToken !== null && authCode === null) {
    forwardEmailToHelpdeskByAccessToken(restId, graphToken)
      .then((res) => {
        if (!res.ok) {
          toggleDisplay("report-email-error", "flex");
        }
      })
      .catch(function (error) {
        console.log("ForwardingCurrentEmailToHelpdesk error");
        console.error(error);
      });
  } else {
    // No graph token, but has authCode
    forwardEmailToHelpdeskByAuthCode(restId, authCode)
      .then((res) => {
        if (!res.ok) {
          toggleDisplay("report-email-error", "flex");
        }
      })
      //  })
      .catch(function (error) {
        console.log("ForwardingCurrentEmailToHelpdesk error");
        console.error(error);
      });
  }
}

function moveCurrentEmailToDeleted() {
  const restId = getItemRestId();
  if (graphToken !== null && authCode === null) {
    moveEmailToDeletedByAccessToken(restId, graphToken)
      .then((res) => {
        if (!res.ok) {
          document.getElementById("move-email-error").style.display = "flex";
        } else {
          // Close taskpane after successful deletion
          Office.context.ui.closeContainer();
        }
      })
      .catch(function (error) {
        console.log("MoveEmailToDeleted error");
        console.error(error);
      });
  } else {
    moveEmailToDeletedByAuthCode(restId, authCode)
      .then((res) => {
        if (!res.ok) {
          document.getElementById("move-email-error").style.display = "flex";
        } else {
          // Close taskpane after successful deletion
          Office.context.ui.closeContainer();
        }
      })
      .catch(function (error) {
        console.log("MoveEmailToDeleted error");
        console.error(error);
      });
  }
}

function reportCurrentEmailAndMoveToDeleted() {
  const restId = getItemRestId();
  if (graphToken !== null && authCode === null) {
    forwardEmailToHelpdeskByAccessToken(restId, graphToken)
      .then((res) => {
        if (!res.ok) {
          toggleDisplay("report-email-error", "flex");
        } else {
          moveCurrentEmailToDeleted();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  } else {
    forwardEmailToHelpdeskByAuthCode(restId, authCode)
      .then((res) => {
        if (!res.ok) {
          toggleDisplay("report-email-error", "flex");
        } else {
          moveCurrentEmailToDeleted();
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }
}

  //API-section starting here
  function getBaseUrl() {
    let url: string;
    let environment: string = 'development';

    if (process.env.APP_ENV === 'development') {
      environment = 'development';
    } 
    if (process.env.APP_ENV === 'staging') {
      environment = 'staging';
    }
    if (process.env.APP_ENV === 'production') {
      environment = 'production';
    }

    switch (environment) {
      case 'production':
        url = 'https://app.look2x.de/public';
        break;
      case 'staging':
        url = 'https://staging.app.look2x.de/public';
        break;
      case 'development':
        url = 'https://a522-2003-eb-272c-fe00-8059-76aa-2165-9c91.ngrok-free.app/public';
        break;
      default:
        url = 'https://app.look2x.de/public';
    }

    return url;
  }

  async function hasDispatchByAccessToken(restId: string, accessToken: string) {
    try {
      const response = await fetch(getBaseUrl() + `/addin/dispatchexistence`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken,
          restId: restId,
        })
      })
      return response
    } catch (error) {
      toggleDisplay("app-body-api-not-accessible", "flex");
      console.error("Could not check if dispatch exists: " + error);
    }
  }

  async function hasDispatchByAuthCode(restId: string, authCode: string) {
    try {
      const response = await fetch(getBaseUrl() + `/addin/dispatchexistence`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authCode: authCode,
          restId: restId,
        })
      })
      return response
    } catch (error) {
      toggleDisplay("app-body-api-not-accessible", "flex");
      console.error("Could not check if dispatch exists: " + error);
    }
  }


  async function moveEmailToDeletedByAccessToken(restId: string, accessToken: string) {
    try {
      const response = await fetch(getBaseUrl() + `/addin/emaildeletion`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken,
          restId: restId
        })
      })
      return response
    } catch (error) {
      //Show error message
      toggleDisplay("move-to-deleted", "none");
      toggleDisplay("report-and-move-to-deleted", "none");
      toggleDisplay("move-email-error", "flex");
    }
  }

  async function moveEmailToDeletedByAuthCode(restId: string, authCode: string) {
    try {
      const response = await fetch(getBaseUrl() + `/addin/emaildeletion`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authCode: authCode,
          restId: restId
        })
      })
      return response
    } catch (error) {
      //Show error message
      toggleDisplay("move-to-deleted", "none");
      toggleDisplay("report-and-move-to-deleted", "none");
      toggleDisplay("move-email-error", "flex");
    }
  }

  async function forwardEmailToHelpdeskByAccessToken(restId: string, accessToken: string) {
    try {
      const response = await fetch(getBaseUrl() + `/addin/emailforwarding`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          accessToken: accessToken,
          restId: restId,
        })
      })
      return response
    } catch (error) {
      //Show error message and hide btn
      toggleDisplay("report-and-move-to-deleted", "none");
      toggleDisplay("report-email-error", "flex");
    }
  }

  async function forwardEmailToHelpdeskByAuthCode(restId: string, authCode: string) {
    try {
      const response = await fetch(getBaseUrl() + `/addin/emailforwarding`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authCode: authCode,
          restId: restId,
        })
      })
      return response
    } catch (error) {
      //Show error message and hide btn
      toggleDisplay("report-and-move-to-deleted", "none");
      toggleDisplay("report-email-error", "flex");
    }
    /* Currently unused api calls
    async function exchangeCodeWithToken(authCode: string, codeVerifier: string) {
      try {
        const response = await fetch(getBaseUrl() + `/addin/tokenexchange`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            code: authCode,
            codeVerifier: codeVerifier
          })
        })
        return response
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchInternetHeaders(restId: string, accessToken: string) {
      try {
        const response = await fetch(`https://graph.microsoft.com/v1.0/me/messages/${restId}?$select=internetMessageHeaders`, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        })
        const result = await response.json();
        console.log(result);
        const messageHeaders = result.internetMessageHeaders;
        return messageHeaders;
      } catch (error) {
        console.error(error);
      }
    }
*/
  }